const Manager = [
  {
    title: '首页',
    key: 'Home',
    icon: 'fi fi-home'
  },
  {
    title: '凭证',
    key: 'vouchers',
    icon: 'fi fi-pz',
    children: [{
      title: '新增凭证',
      key: 'VoucherForm'
    },
      {
        title: '凭证列表',
        key: 'Voucher'
      },
    ]
  },
  {
    title: '账簿',
    key: 'AccountBook',
    icon: 'fi fi-zb',
    children: [
      {
        title: '明细账',
        key: 'DetailedAccounts'
      },
      {
        title: '总账',
        key: 'GeneralLedger'
      },
      {
        title: '科目余额表',
        key: 'SubjectBalance'
      },
      {
        title: '科目汇总表',
        key: 'SubjectSummary'
      },
      {
        title: '时序账',
        key: 'TimeSeries'
      },
      {
        title: '核算项目明细账',
        key: 'AuxiliaryAccountingDetail'
      },
      {
        title: '核算项目余额表',
        key: 'AuxiliaryAccountingBalance'
      }
    ]
  },
  {
    title: '报表',
    key: 'ReportList',
    icon: 'fi fi-bb',
    children: [
      {
        title: '自定义报表',
        key: 'BiReport'
      }
    ]
  },
  {
    title: '资金',
    key: 'funds',
    icon: 'fi fi-zj',
    children: [
      {
        title: '日记账',
        key: 'CashierJournal'
      },
      {
        title: '内部转账',
        key: 'InternalTransfer'
      },
      {
        title: '资金报表',
        key: 'FundsReport'
      },
      {
        title: '核对总账',
        key: 'CheckUp'
      },
      {
        title: '收支类别',
        key: 'CashierIoType'
      },
      {
        title: '账户设置',
        key: 'CashierAccount'
      }
    ]
  },
  {
    title: '薪资',
    key: 'SalaryM',
    icon: 'fi fi-fp',
    children: [
      {
        title: '工资信息',
        key: 'Salary'
      },
      {
        title: '员工信息',
        key: 'Emp'
      },
      {
        title: '五险一金设置',
        key: 'SocialSecurity'
      },
    ]
  },
  {
    title: '发票',
    key: 'Invoice',
    icon: 'fi fi-fp',
  },
  {
    title: '固定资产',
    key: 'assets',
    icon: 'fi fi-gdzc',
    children: [
      {
        title: '固定资产管理',
        key: 'Assets'
      },
      {
        title: '固定资产类别',
        key: 'AssetsType'
      },
      {
        title: '折旧明细表',
        key: 'AssetsDepreciationList'
      },
      {
        title: '固定资产汇总',
        key: 'AssetsSummary'
      },
      {
        title: '固定资产对账',
        key: 'AssetsCheck'
      }
    ]
  },
  {
    title: '期末结转',
    key: 'Checkout',
    icon: 'fi fi-qmjz',
  },
  {
    title: '设置',
    key: 'Setting',
    icon: 'fi fi-sz',
    children: [
      {
        title: '账套',
        key: 'Account'
      },
      {
        title: '科目',
        key: 'Subject'
      },
      {
        title: '期初',
        key: 'Initial'
      },
      {
        title: '币别',
        key: 'Currency'
      },
      {
        title: '凭证字',
        key: 'VoucherWord'
      }, {
        title: '辅助核算',
        key: 'AssistingAccounting'
      },{
        title: '用户管理',
        key:  'User'
      }/*, {
				title: '凭证模板',
				key: 'TemplateManager'
			}*/, {
        title: '权限设置',
        key: 'PermissionSetting'
      }, {
        title: '旧账同步',
        key: 'Sync'
      }, {
        title: '备份恢复',
        key: 'BakUp'
      }
    ]
  }
];

const Making = [
  {
    title: '首页',
    key: 'Home',
    icon: 'fi fi-home'
  },
  {
    title: '凭证',
    key: 'vouchers',
    icon: 'fi fi-pz',
    children: [{
      title: '新增凭证',
      key: 'VoucherForm'
    },
      {
        title: '凭证列表',
        key: 'Voucher'
      },
    ]
  },
  {
    title: '账簿',
    key: 'AccountBook',
    icon: 'fi fi-zb',
    children: [
      {
        title: '明细账',
        key: 'DetailedAccounts'
      },
      {
        title: '总账',
        key: 'GeneralLedger'
      },
      {
        title: '科目余额表',
        key: 'SubjectBalance'
      },
      {
        title: '科目汇总表',
        key: 'SubjectSummary'
      },
      {
        title: '时序账',
        key: 'TimeSeries'
      },
      {
        title: '核算项目明细账',
        key: 'AuxiliaryAccountingDetail'
      },
      {
        title: '核算项目余额表',
        key: 'AuxiliaryAccountingBalance'
      }
    ]
  },
  {
    title: '报表',
    key: 'ReportList',
    icon: 'fi fi-bb',
    children: []
  },
  {
    title: '资金',
    key: 'funds',
    icon: 'fi fi-zj',
    children: [
      {
        title: '日记账',
        key: 'CashierJournal'
      },
      {
        title: '内部转账',
        key: 'InternalTransfer'
      },
      {
        title: '资金报表',
        key: 'FundsReport'
      },
      {
        title: '核对总账',
        key: 'CheckUp'
      },
      {
        title: '收支类别',
        key: 'CashierIoType'
      },
      {
        title: '账户设置',
        key: 'CashierAccount'
      }
    ]
  },
  // {
  //   title: '薪资',
  //   key: 'Salary',
  //   icon: 'fi fi-fp',
  // },
  {
    title: '发票',
    key: 'Invoice',
    icon: 'fi fi-fp',
  },
  {
    title: '固定资产',
    key: 'assets',
    icon: 'fi fi-gdzc',
    children: [
      {
        title: '固定资产管理',
        key: 'Assets'
      },
      {
        title: '固定资产类别',
        key: 'AssetsType'
      },
      {
        title: '折旧明细表',
        key: 'AssetsDepreciationList'
      },
      {
        title: '固定资产汇总',
        key: 'AssetsSummary'
      },
      {
        title: '固定资产对账',
        key: 'AssetsCheck'
      }
    ]
  },
  {
    title: '期末结转',
    key: 'Checkout',
    icon: 'fi fi-qmjz',
  },
  {
    title: '设置',
    key: 'Setting',
    icon: 'fi fi-sz',
    children: [
      {
        title: '账套',
        key: 'Account'
      },
      {
        title: '科目',
        key: 'Subject'
      },
      {
        title: '期初',
        key: 'Initial'
      },
      {
        title: '币别',
        key: 'Currency'
      },
      {
        title: '凭证字',
        key: 'VoucherWord'
      }, {
        title: '辅助核算',
        key: 'AssistingAccounting'
      }
    ]
  }
];

const View = [
  {
    title: '首页',
    key: 'Home',
    icon: 'fi fi-home'
  },
  {
    title: '凭证',
    key: 'vouchers',
    icon: 'fi fi-pz',
    children: [
      {
        title: '凭证列表',
        key: 'Voucher'
      },
    ]
  },
  {
    title: '账簿',
    key: 'AccountBook',
    icon: 'fi fi-zb',
    children: [
      {
        title: '明细账',
        key: 'DetailedAccounts'
      },
      {
        title: '总账',
        key: 'GeneralLedger'
      },
      {
        title: '科目余额表',
        key: 'SubjectBalance'
      },
      {
        title: '科目汇总',
        key: 'SubjectSummary'
      },
      {
        title: '时序账',
        key: 'TimeSeries'
      },
      {
        title: '核算项目明细账',
        key: 'AuxiliaryAccountingDetail'
      },
      {
        title: '核算项目余额',
        key: 'AuxiliaryAccountingBalance'
      }
    ]
  },
  {
    title: '报表',
    key: 'ReportList',
    icon: 'fi fi-bb',
    children: []
  },
  // {
  //   title: '薪资',
  //   key: 'Salary',
  //   icon: 'fi fi-fp',
  // },
];

export default {
  Manager,
  Making,
  View
};
