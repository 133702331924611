<template>
  <div @click="openLayer">
    <slot/>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年10月16日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {layer} from "@layui/layer-vue";
import {routeList} from "@/router";
import {h} from "vue";

export default {
  name: "RouterLink",
  emits: ['success'],
  props: {
    to: Object,
    width: {
      type: String,
      default: "50vw"
    },
  },
  data() {
    return {
      title: ""
    }
  },
  methods: {
    openLayer() {
      let find = routeList.find(value => value.name === this.to.name);
      this.title = find.title;
      console.log(this.to.params)
      let layerId = layer.drawer({
        title: this.title,
        shadeClose: false,
        zIndex: 999,
        area: [this.width, '100vh'],
        content: h(find.component, Object.assign(this.to.params, {
          onSuccess: (args) => {
            this.$emit('success', args)
          },
          onClose: (args) => {
            layer.close(layerId);
          }
        })),
        onClose: (args) => {
          this.$emit('success', args)
        }
      });
    }
  },
}
</script>
