<template>
  <div class="modal-column">
    <div class="modal-column-body mb-32px">
      <div class="my-32px text-18px">
        请在服务商处购买续期码，如已购买，请输入续期码：
      </div>
      <div class="h-input-group">
        <span class="h-input-addon">续期码</span>
        <Input v-model="code" placeholder="请输入续期码"/>
      </div>
    </div>
    <div class="modal-column-between">
      <Button :loading="loading" @click="$emit('close')">取消</Button>
      <Button :loading="loading" :disabled="!code" color="primary" @click="doConfirm">确认续期</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年12月05日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import common from "@/api/common";
import {mapMutations} from "vuex";
import {message} from "heyui.ext";

export default {
  name: "Renewal",
  data() {
    return {
      code: null,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['updateAccount']),
    doConfirm() {
      if (this.code) {
        this.loading = true;
        common.renewalValid(this.code).then(({data}) => {
          this.updateAccount(data);
          message("续期成功~");
          this.$emit('success');
        }).finally(() => this.loading = false)
      }
    }
  },
}
</script>

<style scoped>

</style>
