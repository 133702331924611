import Ajax from "@/js/common/ajax";
import Qs from "qs";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年10月28日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  list(accountId, page, params = null) {
    return Ajax.post("/cashier/journal/list/" + accountId, params, {params: page})
  },
  delete(id) {
    return Ajax.delete(`/cashier/journal/${id}`)
  },
  batchDelete(ids) {
    return Ajax.delete(`/cashier/journal/batch?` + Qs.stringify({id: ids}, {arrayFormat: 'repeat'}))
  },
  batchUpdate(ids, type, params) {
    return Ajax.put(`/cashier/journal/batch?type=${type}&` + Qs.stringify({id: ids}, {arrayFormat: 'repeat'}), params)
  },
  save(params = {}) {
    return Ajax.post(`/cashier/journal`, params)
  },
  generateVoucher(params = []) {
    return Ajax.post(`/cashier/journal/generate/voucher`, params)
  },
  import(params) {
    return Ajax.post(`/cashier/journal/import`, params, {'Content-Type': 'multipart/form-data'})
  }
}
