<template>
  <div id="app">
    <Layout class="app-frame" :siderCollapsed="sliderCollapsed" :siderFixed="layoutConfig.siderFixed">
      <Sider :theme="layoutConfig.siderTheme">
        <appMenu :theme="layoutConfig.siderTheme"></appMenu>
      </Sider>
      <Layout :headerFixed="layoutConfig.headerFixed">
        <HHeader theme="white">
          <appHead @openSetting="openSetting=true" :layoutConfig="layoutConfig"></appHead>
        </HHeader>
        <Content class="flex flex-column">
          <app-tabs/>
          <div class="app-frame-content flex1 p-14px" style="height: 0;overflow-y: auto">
            <template v-for="tab in tabs">
              <component v-show="currentTab === tab.key" is="ReportView" :dimension="tab.params.dimension" :reportId="tab.params.reportId" v-if="tab.key.startsWith('ReportView')"/>
              <component v-show="currentTab === tab.key" :is="tab.key" v-else/>
            </template>
          </div>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>
<script>

import appHead from './app/app-header';
import appMenu from './app/app-menu';
import appFooter from './app/app-footer';
import {mapGetters, mapMutations, mapState} from 'vuex';
import AppTabs from "@/views/app/app-tabs.vue";

export default {
  name: "FXY",
  data() {
    return {
      selectedTab: "Home",
      isRouterAlive: true,
      layoutConfig: {
        siderTheme: 'dark',
        showSystab: false,
        headerFixed: true,
        siderFixed: true
      }
    };
  },
  watch: {
    currentTab(val) {
      this.selectedTab = val;
      localStorage.setItem("currentTab", JSON.stringify(this.tabMap[val]));
      location.hash = val;
    }
  },
  methods: {
    ...mapMutations(['changeTab', 'newTab']),
    closeTab(key) {
      this.$store.commit('closeTab', key);
    },
    tabClick({key}) {
      if (this.currentTab !== key) {
        this.$store.commit('changeTab', key);
      }
    }
  },
  computed: {
    ...mapState(['sliderCollapsed', 'tabs', 'currentTab', 'menus']),
    ...mapGetters(['tabMap'])
  },
  components: {
    AppTabs,
    appHead,
    appMenu,
    appFooter
  },
  created() {
    const currentTab = localStorage.getItem("currentTab")
    if (currentTab) {
      this.newTab(JSON.parse(currentTab));
    }
  }
};
</script>
<style scoped lang="less">
.h-icon-close-min {
  margin-left: 10px;
  font-weight: bold;

  &:hover {
    color: #f60 !important;
  }
}
</style>
