import Ajax from '../js/common/ajax';
import Qs from "qs";

export default {
	//工资自定义字段
	field: {
		parentList() {
			return Ajax.get("/salaryField/parentList")
		},
		listAll() {
			return Ajax.get("/salaryField/listAll")
		},
		listByDate(params) {
			return Ajax.get("/salaryField/listByDate", params)
		},
		list(params) {
			return Ajax.get("/salaryField", params)
		},
		load(id) {
			return Ajax.get(`/salaryField/${id}`)
		},
		save(params) {
			return Ajax[params.id ? 'put' : 'post'](`/salaryField`, params)
		},
		delete(id) {
			return Ajax.delete(`/salaryField/${id}`)
		},
	},
	//工资
	fieldValue: {
		salary(params) {
			return Ajax.get("/salaryFieldValue/salary", params)
		},
		update(params) {
			return Ajax.post("/salaryFieldValue/update/save", params)
		},
		import(localDate, form) {
			return Ajax.post(`/salaryFieldValue/import/${localDate}`, form, {'Content-Type': 'multipart/form-data'})
		},
		download(localDate, params) {
			return Ajax.get(`/salaryFieldValue/toDownload/${localDate}`, params, {responseType: 'blob'})
		},
	},
	//工资条
	schedule: {
		save(params) {
			return Ajax.put("/salarySchedule/save", params)
		},
		createSchedule(params) {
			return Ajax.get("/salarySchedule/createSchedule", params)
		},
		batchDelete(localDate, ids) {
			return Ajax.delete(`/salarySchedule/batchDelete/${localDate}?` + Qs.stringify({id: ids}, {arrayFormat: 'repeat'}))
		},
		print(localDate, params) {
			return Ajax.post(`/pdf/schedule/print/${localDate}`, params, {responseType: 'blob'})
		}
	},
	voucher: {
		listBy(params) {
			return Ajax.get("/salaryVoucher/listBy", params)
		},
		loadVoucher(params) {
			return Ajax.get("/salaryVoucher/loadVoucher", params)
		},
		generateVoucher(params) {
			return Ajax.get("/salaryVoucher/generate/voucher", params)
		}
	},
	voucherTemplate: {
		save(params) {
			return Ajax.post("/voucherTemplate", params)
		},
		list(type) {
			return Ajax.get("/voucherTemplate", {type})
		},
		remove(id) {
			return Ajax.delete("/voucherTemplate/" + id)
		}
	}
}