/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023  武汉幺零幺科技有限公司  All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年04月07日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export const Customer = {
	list(params) {
		return Ajax.get("/customer", params)
	},
	delete(id) {
		return Ajax.delete(`/customer/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/customer`, params)
	},
	update(params = {}) {
		return Ajax.put(`/customer`, params)
	},
}

export const Vendor = {
	list(params) {
		return Ajax.get("/vendor", params)
	},
	delete(id) {
		return Ajax.delete(`/vendor/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/vendor`, params)
	},
	update(params = {}) {
		return Ajax.put(`/vendor`, params)
	},
}

export const Stocks = {
	list(params) {
		return Ajax.get("/stocks", params)
	},
	delete(id) {
		return Ajax.delete(`/stocks/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/stocks`, params)
	},
	update(params = {}) {
		return Ajax.put(`/stocks`, params)
	},
}

export const Project = {
	list(params) {
		return Ajax.get("/project", params)
	},
	delete(id) {
		return Ajax.delete(`/project/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/project`, params)
	},
	update(params = {}) {
		return Ajax.put(`/project`, params)
	},
}

export const Organization = {
	list(params) {
		return Ajax.get("/organization", params)
	},
	delete(id) {
		return Ajax.delete(`/organization/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/organization`, params)
	},
	update(params = {}) {
		return Ajax.put(`/organization`, params)
	},
}
export const Employee = {
	list(params) {
		return Ajax.get("/employee", params)
	},
	delete(id) {
		return Ajax.delete(`/employee/${id}`)
	},
	deleteBy(id) {
		return Ajax.delete(`/employee/deleteBy/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/employee`, params)
	},
	update(params = {}) {
		return Ajax.put(`/employee`, params)
	},
	saveDetail(params = {}) {
		return Ajax.post(`/employee/createDetail`, params)
	},
	detailList(params) {
		return Ajax.get("/employee/detailList", params)
	},
	import(form) {
		return Ajax.post(`/employee/import`, form, {'Content-Type': 'multipart/form-data'})
	},
	download(params) {
		return Ajax.get("/employee/toDownload", params, {responseType: 'blob'})
	}
}
export const Department = {
	list(params) {
		return Ajax.get("/department", params)
	},
	all() {
		return Ajax.get("/department/all")
	},
	delete(id) {
		return Ajax.delete(`/department/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/department`, params)
	},
	update(params = {}) {
		return Ajax.put(`/department`, params)
	},
}

export const CashFlow = {
	list(params) {
		return Ajax.get("/cashFlow", params)
	},
	delete(id) {
		return Ajax.delete(`/cashFlow/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/cashFlow`, params)
	},
	update(params = {}) {
		return Ajax.put(`/cashFlow`, params)
	},
}
