/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年03月08日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {get, toFormatString} from 'xe-utils'
import {toMoney} from 'number-string'
import {Button, Colgroup, Column, Edit, Export, Filter, Icon, Input, Keyboard, Option, Pager, Select, Table, Toolbar, Tooltip, Validator, VXETable} from 'vxe-table'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import 'vxe-table/styles/cssvar.scss'

VXETable.setup({
  size: "mini",
  table: {
    highlightHoverRow: true,
    size: 'mini',
    scrollY: {
      enabled: false
    },
    scrollX: {
      enabled: false
    },
    showOverflow: true,
    columnConfig: {
      resizable: true
    },
    rowConfig: {
      height: 32,
      isCurrent: true,
      isHover: true
    }
  },
  i18n: (key, args) => toFormatString(get(zhCN, key), args)
})

VXETable.formats.add("toMoney", {
  cellFormatMethod: ({cellValue}) => {
    return cellValue && cellValue !== 0 ? toMoney(cellValue, {
      maxPrecision: 2,
      useParens: false,
      symbol: ''
    }) : '';
  }
})

export const useTable = (app) => {
  app.use(Table)
  app.use(Column)
  app.use(Pager)
  app.use(Toolbar)
  app.use(Colgroup)
  app.use(Tooltip)
  app.use(Filter)
  app.use(Icon)
  app.use(Validator)
  app.use(Keyboard)
  app.use(Edit)
  app.use(Select)
  app.use(Input)
  app.use(Export)
  app.use(Option)
  app.use(Button)
}
