import Ajax from '../js/common/ajax';

export default {
	security: {
		save(params) {
			return Ajax[params.id ? 'put' : 'post'](`/social/security`, params)
		},
		list() {
			return Ajax.get("/social/security")
		},
		fieldList() {
			return Ajax.get("/social/security/fieldList")
		},
		delete(id) {
			return Ajax.delete(`/social/security/delete/${id}`)
		},
	},
	socialEmployee: {
		save(params) {
			return Ajax[params.id ? 'put' : 'post'](`/social/employee`, params)
		},
		list(params) {
			return Ajax.get("/social/employee/listBy", params)
		},
	}
}