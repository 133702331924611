/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年10月26日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
    list(params) {
        return Ajax.get("/cashier/account", params)
    },
    delete(id) {
        return Ajax.delete(`/insureConfig/${id}`)
    },
    save(params = {}) {
        return Ajax.post(`/insureConfig`, params)
    },
    update(params) {
        return Ajax.post(`/insureConfig`, params)
    },
    queryInsureInfo(id) {
        return Ajax.get(`/insureConfig/balance/` + id)
    },
    queryInsureProject(id) {
        return Ajax.get(`/insureConfig/project/` + id)
    }
}
