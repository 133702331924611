/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年10月22日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
    list(params) {
        return Ajax.get("/user", params)
    },
    accountSets(userId) {
        return Ajax.get("/user/accountSets/" + userId)
    },
    updateEffectiveDate(asId, effectiveDate) {
        return Ajax.get("/user/update/accountSets/effectiveDate/" + asId, {effectiveDate})
    },
    authAccountSets(userId, params) {
        return Ajax.post("/user/authAccountSets/" + userId, params)
    },
    resetPwd(userId) {
        return Ajax.post("/user/resetPassword/" + userId)
    },
    save(params) {
        return Ajax.post("/user", params)
    }
}
