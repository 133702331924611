/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2023  武汉幺零幺科技有限公司  All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年07月30日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */

import Ajax from '../js/common/ajax';

export default {
  list() {
    return Ajax.get("/bi")
  },
  load(id) {
    return Ajax.get(`/bi/${id}`)
  },
  data(id, voucherDate) {
    return Ajax.get(`/bi/data/${id}`, {voucherDate})
  },
  delete(id) {
    return Ajax.delete(`/bi/${id}`)
  },
  save(params = {}) {
    return Ajax[params.id ? 'put' : 'post'](`/bi`, params)
  }
}
