/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : 凭证</li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年08月01日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	list(params) {
		return Ajax.get("/assets/type", params)
	},
	delete(id) {
		return Ajax.delete(`/assets/type/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/assets/type`, params)
	},
	update(params = {}) {
		return Ajax.put(`/assets/type`, params)
	},
}
