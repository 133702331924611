import Ajax from "@/js/common/ajax";
import Qs from "qs";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年10月28日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	list(params = null) {
		return Ajax.get("/cashier/transfer", params)
	},
	delete(id) {
		return Ajax.delete(`/cashier/transfer/${id}`)
	},
	batchDelete(ids) {
		return Ajax.delete(`/cashier/transfer/batch?` + Qs.stringify({id: ids}, {arrayFormat: 'repeat'}))
	},
	save(params = {}) {
		return Ajax.post(`/cashier/transfer`, params)
	}
}
