<template>
  <div class="login-frame">
    <div class="login-frame-title">注册</div>
    <Form class="login" ref="loginForm" :model="reg" :rules="{}" :validOnChange="true">
      <FormItem :showLabel="false" required prop="mobile" label="手机号">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon">
            <i class="fa fa-user"></i>
          </div>
          <Input v-model="reg.mobile" type="text" placeholder="请输入手机号" class="h-42px"/>
        </div>
      </FormItem>
      <FormItem :showLabel="false" required prop="code" label="验证码" class="my-16px">
        <div class=" h-input-prefix">
          <div class="h-input-prefix-icon h-input-prefix-icon-code"></div>
          <div class="h-input-group" style="width: auto;flex: 1">
            <Input v-model="reg.code" type="text" placeholder="验证码" maxlength="4"/>
            <SmsVerificationCode :mobile="reg.mobile"/>
          </div>
        </div>
      </FormItem>
      <FormItem :showLabel="false" class="!pb-0px">
        <Button @click="regSubmit" :loading="loading" color="primary" block>立 即 注 册</Button>
        <div class="text-center my-32px">
          <span class="text-hover" @click="$emit('changeView','LoginForm')">返回登录</span>
        </div>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import SmsVerificationCode from "../../components/SmsVerificationCode"

export default {
  name: 'Registered',
  components: {SmsVerificationCode},
  data() {
    return {
      reg: {
        mobile: "",
        code: "",
      },
      loading: false
    };
  },
  methods: {
    regSubmit() {
      let validResult = this.$refs.loginForm.valid();
      if (validResult.result) {
        this.loading = true;
        this.$api.common.register(this.reg).then(() => {
          window.location.replace('/');
        }).finally(() => {
          this.loading = false
        });
      }
    }
  }
}
</script>
