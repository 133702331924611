<template>
  <div class="modal-column">
    <Tabs :datas="menus" v-model="tab" class="px-16px"/>
    <div>
      <component :is="tab"/>
    </div>
  </div>
</template>
<script>
import PermissionSetting from "@/views/setting/PermissionSetting";
import ChangePassword from "@/views/personal/ChangePassword";
// import ChangePhoneNumber from "@/views/personal/ChangePhoneNumber";

export default {
  components: {ChangePassword, PermissionSetting},
  data() {
    return {
      tab: "PersonalSetting",
      menus: [{
        title: '个人设置',
        key: 'PersonalSetting'
      }, {
        title: '修改密码',
        key: 'ChangePassword'
      }
      // , {
      //   title: '修改手机',
      //   key: 'ChangePhoneNumber'
      // }
      ]
    };
  }
};
</script>
