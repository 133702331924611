<template>
  <div class="flex flex-column h-full p-10px bg-white-color">
    <div class="mb-10px">
      <vxe-button class="mr-16px" status="primary" @click="showForm=true">新增员工</vxe-button>
    </div>
    <div class="flex-1 h-0px">
      <vxe-table height="auto" :data="datas" :border="true">
        <vxe-column title="用户" field="realName"></vxe-column>
        <vxe-column title="手机号" field="mobile"></vxe-column>
        <vxe-column title="权限" field="role" :formatter="({cellValue})=>dictMapping(cellValue,'roles')"></vxe-column>
        <vxe-column>
          <template #default="{row}">
            <div class="actions">
              <span @click="showHandOver=true" v-if="currentAccountSets.creatorId === row.id && row.id === User.id">移交</span>
              <template v-else-if="User.id !== row.id && currentAccountSets.creatorId !== row.id">
                <span @click="edit(row)">编辑</span>
                <span @click="remove(row)">删除</span>
              </template>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <Modal v-model="showForm" hasCloseIcon>
      <template #header>当前账套：{{ currentAccountSets.companyName }}</template>
      <Form ref="form" :labelWidth="80" :model="form" :rules="validationRules" mode="block">
        <FormItem label="手机用户" prop="mobile">
          <Input type="text" v-model="form.mobile" :disabled="form.id"/>
        </FormItem>
        <Radio class="cus-h-radio" v-model="form.role" :datas="roles">
          <template slot="item" slot-scope="{item}">
            <span>{{ item.title }}</span>
            <div class="desc">{{ item.desc }}</div>
          </template>
        </Radio>
      </Form>
      <div class="text-center margin-top w-400px">
        <Button @click="showForm=false">取消</Button>
        <Button color="primary" @click="addUser" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
      </div>
    </Modal>
    <Modal v-model="showNewForm" hasCloseIcon>
      <template #header>新增权限</template>
      <div class="margin">
        亲，您输入的手机还没有注册，确认需要新增吗？
        为保障您的数据安全，新注册用户需要验证手机。
      </div>
      <Row type="flex" :space="10" class="margin">
        <Cell><Input type="text" v-model="form.mobile" disabled/></Cell>
        <Cell>
          <SmsVerificationCode :mobile="form.mobile"/>
        </Cell>
      </Row>
      <Row type="flex" :space="10" class="margin">
        <Cell><Input v-model="msgCode" type="text" placeholder="请输入验证码"/></Cell>
      </Row>
      <div class="text-center">
        <Button @click="showNewForm=false">取消</Button>
        <Button color="primary" @click="addNewUser" :loading="loading">确定</Button>
      </div>
    </Modal>
    <Modal v-model="showHandOver" hasCloseIcon>
      <template #header>移交账套</template>
      <div class="margin">
        <template v-if="authenticated">
          请验证接收人的手机，他即将接收的账套是：
        </template>
        <template v-else>
          请验证移交人的手机，您即将移交的账套是：
        </template>
        <br>
        {{ currentAccountSets.companyName }}
        <p class="red-color">注意：移交账套后，您将不能再查看该账套！</p>
      </div>
<!--      <div v-show="authenticated">-->
<!--        <div class="margin h-input-group">-->
<!--          <Input type="text" v-model="receiverMobile" placeholder="请输入接收人手机"/>-->
<!--          <SmsVerificationCode :mobile="receiverMobile"/>-->
<!--        </div>-->
<!--        <div class="margin">-->
<!--          <Input v-model="verificationCode" type="text" placeholder="请输入验证码"/>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-show="!authenticated">-->
<!--        <div class="margin h-input-group">-->
<!--          <Input type="text" v-model="User.mobile" disabled/>-->
<!--          <SmsVerificationCode :mobile="User.mobile"/>-->
<!--        </div>-->
<!--        <div class="margin">-->
<!--          <Input v-model="verificationCode" type="text" placeholder="请输入验证码"/>-->
<!--        </div>-->
<!--      </div>-->
      <div class="text-center">
        <Button @click="showHandOver=false">取消</Button>
        <Button color="primary" @click="handOver" :loading="loading">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import SmsVerificationCode from "../../components/SmsVerificationCode";
import setting from "@api/setting";

const emptyForm = {
  "role": "Manager",
  "mobile": "",
};

export default {
  name: 'PermissionSetting',
  components: {SmsVerificationCode},
  data() {
    return {
      datas: [],
      roles: [
        {
          key: 'Manager',
          title: '账套管理员',
          desc: '凭证 | 结账 | 账簿 | 报表 | 新增账套 | 删除账套'
        }/*, {
						key: 'Director',
						title: '主管',
						desc: '凭证 | 出纳 | 发票 | 工资 | 固定资产 | 税务 | 期末结转 | 账簿 | 报表 | 新增账套\n'
					}*/, {
          key: 'Making',
          title: '制单人',
          desc: '凭证 | 结账 | 账簿 | 报表 | 新增账套 '
        }/*, {
						key: 'Cashier',
						title: '出纳',
						desc: '出纳 | 查看凭证、固定资产、账簿和报表 | 新增账套'
					}*/, {
          key: 'View',
          title: '查看',
          desc: '查看凭证、账簿和报表 | 新增账套'
        }],
      form: Object.assign({}, emptyForm),
      showNewForm: false,
      showHandOver: false,
      showForm: false,
      loading: false,
      authenticated: false,
      receiverMobile: '',
      msgSended: false,
      msgTxt: '发送验证码',
      msgCode: '',
      verificationCode: '',
      validationRules: {
        required: ['mobile'],
        mobile: ['mobile']
      }
    };
  },
  computed: {
    ...mapState(['currentAccountSets', 'User']),
  },
  watch: {
    showForm(val) {
      if (!val) {
        this.reset();
      }
    }
  },
  methods: {
    loadList() {
      setting.user.list().then(({data}) => {
        this.datas = data.results || [];
      })
    },
    addUser() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        setting.accountSets[this.form.id ? 'updateUserRole' : 'addUser'](this.form).then(() => {
          this.loadList();
          this.showForm = false;
          this.loading = false;
        }).catch((rs) => {
          this.loading = false;
          if (rs.code === 501) {
            this.showNewForm = true;
          }
        });
      }
    },
    addNewUser() {
      if (!this.msgCode) {
        this.$Message('请输入验证码！');
        return
      }

      this.loading = true;
      setting.accountSets.addNewUser(Object.assign({}, this.form, {code: this.msgCode})).then(() => {
        this.loadList();
        this.showForm = false;
        this.showNewForm = false;
        this.loading = false;
      }).catch((rs) => {
        this.loading = false;
      });
    },
    edit(data) {
      this.form = {
        role: data.role,
        mobile: data.mobile,
        id: data.id
      };
      this.showForm = true;
    },
    remove(data) {
      this.$Confirm("确认删除?").then(() => {
        setting.accountSets.removeUser(data.id).then(() => {
          this.loadList();
        })
      })
    },
    reset() {
      this.form = Object.assign({}, emptyForm);
    },
    handOver() {
      // if (this.verificationCode) {
      //   if (!this.authenticated) {
      //     //移交人身份确认
      //     setting.accountSets.identification({code: this.verificationCode}).then(() => {
      //       this.verificationCode = "";
      //       this.authenticated = true;
      //     });
      //   } else {
          //移交亲，请再次确定是否移交该账套，移交成功后，接收人登录小零通云财务即可查看该账套
      setting.accountSets.handOver({mobile: this.receiverMobile}).then(() => {
        window.location.replace("/");
      });
      //   }
      // }
    }
  },
  mounted() {
    this.loadList();
  }
};
</script>
<style lang="less">
.cus-h-radio {
  > label {
    display: block;
    height: auto;

    .desc {
      color: @dark4-color;
      padding-left: 20px;
    }
  }
}
</style>
