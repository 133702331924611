/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年08月25日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	list() {
		return Ajax.get("/checkout")
	},
	initialCheck(params) {
		return Ajax.get("/checkout/initialCheck", params)
	},
	finalCheck(params) {
		return Ajax.get("/checkout/finalCheck", params)
	},
	reportCheck(params) {
		return Ajax.get("/checkout/reportCheck", params)
	},
	brokenCheck(params) {
		return Ajax.get("/checkout/brokenCheck", params)
	},
	reviewedCheck(params) {
		return Ajax.get("/checkout/reviewedCheck", params)
	},
	profitCheck(year, month) {
		return Ajax.get("/checkout/profitCheck", {year, month})
	},
	invoicing(params) {
		return Ajax.get("/checkout/invoicing", params)
	},
	unCheck(params) {
		return Ajax.get("/checkout/unCheck", params)
	},
	templateList(year, month) {
		return Ajax.get("/checkout/template", {year, month})
	},
	loadTplData(params) {
		return Ajax.get("/checkout/template/data", params)
	},
	beginCheck() {
		return Ajax.get("/checkout/begin/check")
	}
}
