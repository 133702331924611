import {defineAsyncComponent} from "vue";

const routes = [
  {name: 'Home', title: '让每个决策都有数据支撑', component: defineAsyncComponent(() => import('@/views/Home.vue'))},
  {name: 'Voucher', title: '凭证列表', component: defineAsyncComponent(() => import('@/views/voucher/Index.vue'))},
  {name: 'VoucherForm', title: '凭证信息', component: defineAsyncComponent(() => import('@/views/voucher/VoucherForm.vue'))},
  {name: 'CheckList', title: '期末结转', component: defineAsyncComponent(() => import('@/views/checkout/CheckList.vue'))},
  {name: 'Checkout', title: '结账', component: defineAsyncComponent(() => import('@/views/checkout/Checkout.vue'))},
  {name: 'Account', title: '账套', component: defineAsyncComponent(() => import('@/views/setting/Account.vue'))},
  {name: 'Subject', title: '科目', component: defineAsyncComponent(() => import('@/views/setting/Subject.vue'))},
  {name: 'Initial', title: '期初', component: defineAsyncComponent(() => import('@/views/setting/Initial.vue'))},
  {name: 'VoucherWord', title: '凭证字', component: defineAsyncComponent(() => import('@/views/setting/VoucherWord.vue'))},
  {name: 'Currency', title: '币别', component: defineAsyncComponent(() => import('@/views/setting/Currency.vue'))},
  {name: 'AssistingAccounting', title: '辅助核算', component: defineAsyncComponent(() => import('@/views/setting/AssistingAccounting.vue'))},
  {name: 'PermissionSetting', title: '权限设置', component: defineAsyncComponent(() => import('@/views/setting/PermissionSetting.vue'))},
  {name: 'PersonalSetting', title: '个人设置', component: defineAsyncComponent(() => import('@/views/personal/PersonalSetting.vue'))},
  {name: 'DetailedAccounts', title: '明细账', component: defineAsyncComponent(() => import('@/views/accountbook/DetailedAccounts.vue'))},
  {name: 'GeneralLedger', title: '总账', component: defineAsyncComponent(() => import('@/views/accountbook/GeneralLedger.vue'))},
  {name: 'SubjectBalance', title: '科目余额', component: defineAsyncComponent(() => import('@/views/accountbook/SubjectBalance.vue'))},
  {name: 'SubjectSummary', title: '科目汇总', component: defineAsyncComponent(() => import('@/views/accountbook/SubjectSummary.vue'))},
  {name: 'AuxiliaryAccountingBalance', title: '辅助核算余额', component: defineAsyncComponent(() => import('@/views/accountbook/AuxiliaryAccountingBalance.vue'))},
  {name: 'AuxiliaryAccountingDetail', title: '辅助核算明细账', component: defineAsyncComponent(() => import('@/views/accountbook/AuxiliaryAccountingDetail.vue'))},
  {name: 'TimeSeries', title: '时序账', component: defineAsyncComponent(() => import('@/views/accountbook/TimeSeries.vue'))},
  {name: 'ReportView', title: '报表数据', component: defineAsyncComponent(() => import('@/views/report/ReportView.vue'))},
  {name: 'CashierAccount', title: '账户设置', component: defineAsyncComponent(() => import('@/views/cashier/CashierAccount.vue'))},
  {name: 'CashierIoType', title: '收支类别', component: defineAsyncComponent(() => import('@/views/cashier/CashierIoType.vue'))},
  {name: 'CashierJournal', title: '日记账', component: defineAsyncComponent(() => import('@/views/cashier/CashierJournal.vue'))},
  {name: 'InternalTransfer', title: '内部转账', component: defineAsyncComponent(() => import('@/views/cashier/InternalTransfer.vue'))},
  {name: 'FundsReport', title: '资金报表', component: defineAsyncComponent(() => import('@/views/cashier/FundsReport.vue'))},
  {name: 'CheckUp', title: '核对总账', component: defineAsyncComponent(() => import('@/views/cashier/CheckUp.vue'))},
  {name: 'Assets', title: '固定资产管理', component: defineAsyncComponent(() => import('@/views/assets/Assets.vue'))},
  {name: 'AssetsType', title: '固定资产类型', component: defineAsyncComponent(() => import('@/views/assets/AssetsType.vue'))},
  {name: 'AssetsDepreciationList', title: '折旧明细表', component: defineAsyncComponent(() => import('@/views/assets/AssetsDepreciationList.vue'))},
  {name: 'AssetsSummary', title: '固定资产汇总', component: defineAsyncComponent(() => import('@/views/assets/AssetsSummary.vue'))},
  {name: 'AssetsCheck', title: '固定资产核对总账', component: defineAsyncComponent(() => import('@/views/assets/AssetsCheck.vue'))},
  {name: 'Invoice', title: '发票', component: defineAsyncComponent(() => import('@/views/invoice/Invoice.vue'))},
  {name: 'BakUp', title: '备份恢复', component: defineAsyncComponent(() => import('@/views/setting/BakUp.vue'))},
  {name: 'Salary', title: '薪资', component: defineAsyncComponent(() => import('@/views/salary/Salary.vue'))},
  {name: 'BiReport', title: '自定义报表', component: defineAsyncComponent(() => import('./views/report/BiReport.vue'))},
  {name: 'Sync', title: '旧账同步', component: defineAsyncComponent(() => import('@/views/sync/Sync.vue'))},
  {name: 'Emp', title: '员工信息', component: defineAsyncComponent(() => import('@/views/salary/HrTable'))},
  {name: 'User', title: '用户管理', component: defineAsyncComponent(() => import('@/views/setting/User.vue'))},
  {name: 'SocialSecurity', title: '保险设置', component: defineAsyncComponent(() => import('@/views/salary/SocialSecurity'))},
]
export const routeList = routes

export default {
  install(vue) {
    routes.forEach(item => vue.component(item.name, item.component))
  }
}
