<template>
  <div class="app-logo" :style="style">

  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "AppLogo",
  computed: {
    ...mapState(['webConfig']),
    style() {
      if (this.webConfig.homeLogo) {
        return {
          backgroundImage: 'url("/api' + this.webConfig.homeLogo + '")'
        }
      }
      return {};
    }
  },
};
</script>
<style lang='less'>
.app-logo {
  line-height: @layout-header-height;
  height: @layout-header-height;
  overflow: hidden;
  white-space: nowrap;
  transition: padding .3s;
  text-align: center;
  background-color: #3C72FF;

  background-image: url("../../assets/logo@3x.png");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 16px;
}

.h-layout-sider-collapsed {
  .app-logo-text {
    display: none;
  }
}
</style>
