import {createApp} from 'vue'
import App from './views/App.vue';
import Login from './views/Login.vue';
import store from './store';
import router from './router';
import api from './api';
import heyui, {confirm, loading, message} from "heyui.ext";
import AppContent from './components/AppContent';
import AccountDateChoose from './components/AccountDateChoose';
import SubMenu from './components/common/sub-menu';
import RouterLink from './components/RouterLink';
import Chart from './views/app/chart/echarts';
import {useTable} from '@js/common/xe-table';
import filters from './js/common/filters';
import '@layui/layer-vue/lib/index.css';
import layer from '@layui/layer-vue';

import './js/config/dict';
import 'windi.css'
import 'font-awesome/css/font-awesome.css'
import './styles/app.less';

let app;
store.dispatch("init").then(() => {
	app = createApp(App);
	app.config.globalProperties.$api = api;
	app.config.globalProperties.$Message = message;
	app.config.globalProperties.$Loading = loading;

	app.config.globalProperties.$Confirm = (content) => {
		return new Promise((resolve, reject) => {
			confirm({
				title: "操作提示",
				content,
				onConfirm: resolve,
				onCancel: reject
			})
		})
	};

	app.use(useTable).use(layer).use(filters).use(router).use(heyui).use(store);
	app.component("app-content", AppContent);
	app.component("account-date-choose", AccountDateChoose);
	app.component('sub-menu', SubMenu);
	app.component('Chart', Chart);
	app.component('RouterLink', RouterLink);
}).catch((err) => {
	app = createApp(Login).use(store).use(heyui).use(filters);
	app.config.globalProperties.$api = api;
	app.config.globalProperties.$Message = message;
}).finally(() => {
	Object.keys(process.env).forEach(key => {
		if (key.startsWith("VUE_APP_")) {
			app.config.globalProperties[key.replace("VUE_APP_", "")] = process.env[key];
		}
	})
	app.mount('#app')
});
