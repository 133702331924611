/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年10月26日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	list(params) {
		return Ajax.get("/cashier/account", params)
	},
	delete(id) {
		return Ajax.delete(`/cashier/account/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/cashier/account`, params)
	},
	update(params) {
		return Ajax.post(`/cashier/account`, params)
	},
	updateInitialBalance(id, balance) {
		return Ajax.get(`/cashier/account/balance/` + id, {balance})
	}
}

export const SalaryBusiness = {
	list() {
		return Ajax.get("/salary/business")
	},
	save(params) {
		return Ajax.post("/salary/business", params)
	},
	remove(id) {
		return Ajax.delete("/salary/business/" + id)
	}
}
