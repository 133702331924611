/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年08月25日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	template: {
		list() {
			return Ajax.get("/report")
		},
		select() {
			return Ajax.get("/report/select")
		},
		load(id) {
			return Ajax.get(`/report/${id}`)
		},
		items: {
			formula(params) {
				return Ajax.put(`/report/items/formula`, params)
			}
		}
	},
	view(templateKey, params = {}) {
		return Ajax.get(`/report/data/${templateKey}`, params)
	},
	print(content) {
		return Ajax.post(`/report/export/pdf`, {content}, {
			responseType: 'blob'
		})
	},
	download(params) {
		return Ajax.post(`/report/export/download`, params, {
			responseType: 'blob'
		})
	}
}
