import {createStore} from 'vuex'
import Common from './api/common'
import menuConfig from './js/config/menu-config';
import setting from "@/api/setting";
import {ArrToObj} from "@/js/common/utils";

const SubjectType = {
    ASSET: "资产",
    LIABILITY: "负债",
    RIGHTS: "权益",
    COST: "成本",
    INCOME: "损益",
    COMMON: "共同",
    NET_ASSETS: "净资产",
    REVENUE: "收入",
    EXPENSES: "费用"
};

export default createStore({
    state: {
        User: {
            name: '小零通',
            desc: '服务零售业',
            master: 0,
            email: 'heming@xlt18.com',
            org: '武汉幺零幺科技有限公司',
            dept: '客户成功',
            title: '工程师',
            location: '武汉市',
            avatar: '/images/avatar.png',
            tags: ['码农', '直男']
        },
        webConfig: {
            "name": "",
            "company": "",
            "loginLogo": "",
            "loginSlogan": "",
            "loginSubSlogan": "",
            "copyright": "",
            "homeLogo": "",
            "qrCode": "",
            "register": true,
            "experienceDays": 30,
            "about": ""
        },
        currentAccountSets: {},
        myAccountSets: [],
        tabs: [{
            key: "Home",
            title: "首页"
        }],
        reportList: [],
        subjectType: [],
        menus: [],
        currentTab: 'Home',
        currencyMap: {},
        sliderCollapsed: false
    },
    mutations: {
        updateTabs(state, payload) {
            state.tabs = payload;
        },
        newTab(state, tab) {
            let find = state.tabs.find(val => val.key === tab.key);
            if (!find) {
                state.tabs.push(tab);
            }
            state.currentTab = tab.key;
        },
        changeTab(state, tabKey) {
            state.currentTab = tabKey;
        },
        updateCurrency(state, currencyMap) {
            state.currencyMap = currencyMap;
        },
        updateWebConfig(state, webConfig) {
            state.webConfig = webConfig;
        },
        updateReportList(state, reportList) {
            state.reportList = reportList;
        },
        refreshTab(state, tab) {
            let find = state.tabs.findIndex(val => val.key === tab.key);
            if (find !== -1) {
                state.tabs.splice(find, 1);
            }
            setTimeout(() => {
                state.tabs.push(tab);
            })
            state.currentTab = tab.key;
        },
        closeTab(state, tabKey) {
            let find = state.tabs.findIndex(val => val.key === tabKey);
            if (find !== -1) {
                let next = Math.max(0, find - 1);
                state.currentTab = state.tabs[next].key;
                state.tabs.splice(find, 1);
            }
        },
        updateAccount(state, data) {
            state.User = data;
            state.currentAccountSets = data.accountSets;
            if (data.accountSets) {
                state.subjectType = data.accountSets.subjectCategory.map(val => SubjectType[val]);
            }
            state.myAccountSets = data.accountSetsList;
            state.menus = menuConfig[data.role];
        },
        updateSliderCollapse(state, isShow) {
            setTimeout(() => {
                G.trigger('page_resize');
            }, 600);
            state.sliderCollapsed = isShow;
        }
    },
    actions: {
        init(context) {
            return new Promise((resolve, reject) => {
                Common.init().then(({data}) => {
                    context.commit('updateAccount', data.user);
                    context.commit('updateCurrency', data.currency);
                    context.commit('updateReportList', data.reportList);
                    context.commit('updateWebConfig', data.webConfig);
                    resolve(data)
                }).catch(({data}) => {
                    context.commit('updateWebConfig', data.webConfig);
                    reject();
                })
            });
        },
        updateCurrency(context) {
            setting.currency.list().then(({data}) => {
                context.commit('updateCurrency', ArrToObj(data, val => val.id));
            })
        }
    },
    getters: {
        /**
         * 本位币
         * @param state
         * @returns {unknown}
         */
        localCurrency: state => {
            return Object.values(state.currencyMap).find(val => val.localCurrency)
        },
        tabMap: state => {
            const map = {};
            state.tabs.forEach(item => {
                map[item.key] = item;
            })
            return map;
        },
        account: state => {
            return state.User;
        },
        checkoutList: state => {
            return state.User.checkoutList;
        },
        firstCheckoutDate: state => {
            let reverse = state.User.checkoutList.filter(val => val.status !== 2).reverse();
            if (reverse.length) {
                return reverse[0].checkDate;
            }
            return null;
        },
        checkoutSelect: state => {
            return state.User.checkoutList.map(row => {
                return {
                    key: row.checkYear + '-' + row.checkMonth,
                    title: row.checkYear + '年' + row.checkMonth + "月",
                }
            });
        },
        sliderCollapsed: state => {
            return state.sliderCollapsed;
        }
    }
})
