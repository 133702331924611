<template>
  <div v-if="currentAccountSets" class="flex items-center">
    <Select
        @change="changeAccountSets"
        :datas="myAccountSets"
        v-model="currentAccountSetsId"
        autosize
        :deletable="false"
        className="as-select"
        keyName="id" titleName="companyName">
    </Select>
    <Tooltip content="账套管理">
      <div @click="editAs" class="fi fi-setting text-hover ml-20px mr-34px !w-22px !h-22px"></div>
    </Tooltip>
  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex';
import {loading, message} from "heyui.ext";

export default {
  name: 'AppHeaderAccountSets',
  data() {
    return {
      messageList: [],
      currentAccountSetsId: null
    };
  },
  methods: {
    ...mapMutations(['newTab']),
    changeAccountSets(as) {
      if (as.id !== this.currentAccountSets.id) {
        loading("正在切换，请稍后...");
        this.$api.common.changeAccountSets(as.id).then(() => {
          message('切换成功！');
          window.location.replace("/")
        });
      }
    },
    editAs() {
      this.newTab({
        key: 'Account',
        title: "账套"
      })
    }
  },
  computed: {
    ...mapState(['currentAccountSets', 'myAccountSets'])
  },
  watch: {
    'currentAccountSets.id'(id) {
      this.currentAccountSetsId = id;
    }
  },
  created() {
    if (this.currentAccountSets) {
      this.currentAccountSetsId = this.currentAccountSets.id;
    }
  }
};
</script>
<style lang='less'>
.app-header-message-vue {
  float: left;
  margin-right: 10px;

  .h-dropdowncustom-show {
    height: @layout-header-height;

    .app-header-icon-item {
      margin-right: 0;
    }

    &.h-pop-trigger {
      background: @hover-background-color;
    }
  }
}

.app-account-sets-dropdown-dropdown-container {
  width: 300px;
  min-height: 300px;

  .common-list-container {
    .common-list-item {
      cursor: pointer;
      padding: 10px;

      &:hover {
        background: @hover-background-color;
      }

      &.current {
        background: @primary-color;
        color: @white-color;
      }
    }
  }
}
</style>
