/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 武汉幺零幺科技有限公司 All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年08月25日</li>
 * <li>@author     :  何明 </li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import manba from 'manba';
import Decimal from 'decimal.js';
import {toClean} from "number-string";
import {isNumber} from "xe-utils";

const FormatNum = function (f, digit = 2) {
	let m = Math.pow(10, digit);
	let des = f * m + 0.5;
	return parseInt(des, 10) / m;
};

const toFixed = function (num, s) {
	return Decimal(num).toFixed(s);
};

export default {
	install(vue) {
		vue.config.globalProperties.dxMoney = (n) => {
			if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n === 0) {
				return "零元整";
			}

			let unit = "仟佰拾亿仟佰拾万仟佰拾元角分", str = "";
			n += "00";
			let p = n.indexOf('.');
			if (p >= 0) {
				n = n.substring(0, p) + n.substr(p + 1, 2);
			}
			unit = unit.substring(unit.length - n.length);
			for (let i = 0; i < n.length; i++) {
				str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
			}

			return str.replace(/零([仟佰拾角])/g, "零").replace(/(零)+/g, "零").replace(/零([万亿元])/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
		}

		vue.config.globalProperties.fqFormat = (n) => {
			if (!n) {
				return ''
			}
			return manba(n).format("YYYY年第MM期");
		};

		vue.config.globalProperties.dFormat = (n, format) => {
			if (!n) {
				return ''
			}
			return manba(n).format(format);
		};

		vue.config.globalProperties.moneyFormat = (n) => {
			if (!n) {
				return '0'
			}
			let num = Math.abs(n);
			if (num < 10000) {
				return n;
			} else if (num >= 10000 && num < 100000000) {
				return FormatNum(n / 10000) + "万";
			} else {
				return FormatNum(n / 100000000, 4) + "亿";
			}
		};

		vue.config.globalProperties.numFormat = (number, dft) => {
			if (!isNumber(number) || !number) {
				return dft || ''
			}
			return toClean(number, {maxPrecision: 2, minPrecision: 2});
		};
	}
}
